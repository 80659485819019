import React from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText } from '@mui/material';
import SEO from '../components/SEO';

const BuildInfo = () => {
  const buildStack = [
    'Vite',
    'React',
    'React Router',
    'Firebase',
    'Vercel',
    'React Query',
    'Hashnode',
    'Tailwind CSS',
    'Material-UI',
    'Sentry',
  ];

  const seoData = {
    title: "Build Info",
    description: "Technical details about the build and deployment of Xyrus Code website.",
    type: "website",
    url: "https://xyruscode.com.ng/build-info",
    image: "https://xyruscode.com.ng/images/build-info-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: "Build Information - Xyrus Code",
      description: "Technical details about the build and deployment of Xyrus Code website",
      url: "https://xyruscode.com.ng/build-info",
    },
  };

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>Build Information</Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>Version Information</Typography>
        <Typography>Release Version: {import.meta.env.VITE_RELEASE_VERSION || 'Unknown'}</Typography>
        <Typography>Environment: {import.meta.env.VITE_ENVIRONMENT || 'Development'}</Typography>
        <Typography>Git Commit: {import.meta.env.VITE_GIT_COMMIT || 'Unknown'}</Typography>
      </Paper>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Build Stack</Typography>
        <List>
          {buildStack.map((tech, index) => (
            <ListItem key={index}>
              <ListItemText primary={tech} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default BuildInfo;