import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, type, url, image, jsonLd }) => {
  const defaultSeo = {
    title: "Xyrus Code",
    description: "Curious mind, developer, and writer.",
    twitter: "@xyruscode",
    twitterCard: "social-card.png",
    ogImage: "og-image.png",
    url: "https://xyruscode.com.ng",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Person",
      name: "Xyrus Code",
      url: "https://xyruscode.com.ng",
      sameAs: ["https://twitter.com/xyruscode", "https://github.com/xyruscode"],
      image:
        "https://xyruscode.com.ng/static/favicons/android-chrome-512x512.png",
      mainEntityOfPage: "https://xyruscode.com.ng",
    },
  };

  const seoTitle = title ? `${title} | Xyrus Code` : defaultSeo.title;
  const seoDescription = description || defaultSeo.description;
  const seoUrl = url || defaultSeo.url;
  const seoImage = image || defaultSeo.ogImage;
  const seoType = type || "website";

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content={seoType} />
      <meta property="og:url" content={seoUrl} />
      <meta property="og:image" content={seoImage} />
      <meta property="twitter:card" content={defaultSeo.twitterCard} />
      <meta property="twitter:creator" content={defaultSeo.twitter} />
      <meta property="twitter:title" content={seoTitle} />
      <meta property="twitter:description" content={seoDescription} />
      <meta property="twitter:image" content={seoImage} />
      <script type="application/ld+json">
        {JSON.stringify(jsonLd || defaultSeo.jsonLd)}
      </script>
    </Helmet>
  );
};

export default SEO;