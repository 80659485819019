// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6Ct9lla1tJqyD7yq_L7MmbVna16e63z4",
  authDomain: "xyrus-xode.firebaseapp.com",
  projectId: "xyrus-xode",
  storageBucket: "xyrus-xode.appspot.com",
  messagingSenderId: "941543636175",
  appId: "1:941543636175:web:b3b0b3995befef124b4bba",
  measurementId: "G-SHXYH341LQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);

export { app, analytics, db };
