import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchBlogPosts } from '../queries';

const Sitemap = () => {
  const { data: blogData } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: fetchBlogPosts,
  });

  const { data: projectsData } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      const response = await fetch('/data/projects.json');
      return response.json();
    },
  });

  const baseUrl = 'https://xyruscode.com.ng';

  const staticRoutes = [
    '/',
    '/about',
    '/projects',
    '/blog',
    '/contact',
    '/store',
  ];

  const blogPosts = blogData?.data?.publication?.posts?.edges || [];
  const projects = projectsData || [];

  const generateSitemapXml = () => {
    let sitemap = '<?xml version="1.0" encoding="UTF-8"?>\n';
    sitemap += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

    staticRoutes.forEach(route => {
      sitemap += `  <url>\n    <loc>${baseUrl}${route}</loc>\n    <changefreq>weekly</changefreq>\n    <priority>0.8</priority>\n  </url>\n`;
    });

    blogPosts.forEach(post => {
      sitemap += `  <url>\n    <loc>${baseUrl}/blog/${post.node.slug}</loc>\n    <changefreq>monthly</changefreq>\n    <priority>0.6</priority>\n  </url>\n`;
    });

    projects.forEach(project => {
      sitemap += `  <url>\n    <loc>${baseUrl}/projects/${project.id}</loc>\n    <changefreq>monthly</changefreq>\n    <priority>0.6</priority>\n  </url>\n`;
    });

    sitemap += '</urlset>';

    return sitemap;
  };

  return (
    <pre>{generateSitemapXml()}</pre>
  );
};

export default Sitemap;