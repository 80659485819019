import React, { Suspense } from 'react';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Button, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import SEO from '../components/SEO';
import { fetchBlogPosts } from '../queries';

// Lazy load sections
const ProjectCard = React.lazy(() => import('../components/ProjectCard'));
const ProductCard = React.lazy(() => import('../components/ProductCard'));
const BlogPostCard = React.lazy(() => import('../components/BlogPostCard'));

const LoadingSkeleton = () => (
  <Card>
    <Skeleton variant="rectangular" height={140} />
    <CardContent>
      <Skeleton variant="text" height={24} />
      <Skeleton variant="text" height={20} />
    </CardContent>
  </Card>
);

const Home = () => {
  const { data: blogData } = useQuery({
    queryKey: ["blogPosts"],
    queryFn: fetchBlogPosts,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });

  const { data: projects = [] } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const response = await fetch("/data/projects.json");
      return response.json();
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });

  const { data: products = [] } = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      const response = await fetch("/data/products.json");
      return response.json();
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });

  const recentProjects = projects.slice(0, 4);
  const recentProducts = products.slice(0, 4);
  const posts = blogData?.data?.publication?.posts?.edges || [];
  const recentPosts = posts.slice(0, 4);

  return (
    <Box className="container mx-auto px-4 py-8 space-y-12">
      <SEO 
        title="Xyrus Code - Software Developer"
        description="Curious mind, developer, and writer."
      />
      
      {/* Intro Section */}
      <section className="max-w-4xl mx-auto text-center space-y-6 px-4 md:px-8">
        <Typography variant="h1" className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 animate-fade-in">
          Welcome to Xyrus Code
        </Typography>
        
        <Typography variant="h2" className="text-xl md:text-2xl lg:text-3xl mb-8 text-muted-foreground">
          I'm a software developer based in Nigeria. I write about the code I write.
        </Typography>
      </section>

      {/* Recent Posts Section */}
      <section className="space-y-6 px-4 md:px-8">
        <Typography variant="h3" className="text-2xl md:text-3xl font-bold mb-6">
          Recent Posts
        </Typography>
        <Grid container spacing={4}>
          {recentPosts.map((post) => (
            <Grid item xs={12} sm={6} lg={3} key={post.node.url}>
              <Suspense fallback={<LoadingSkeleton />}>
                <BlogPostCard post={post.node} />
              </Suspense>
            </Grid>
          ))}
        </Grid>
      </section>

      {/* Recent Projects Section */}
      <section className="space-y-6 px-4 md:px-8">
        <Typography variant="h3" className="text-2xl md:text-3xl font-bold mb-6">
          Recent Projects
        </Typography>
        <Grid container spacing={4}>
          {recentProjects.map((project) => (
            <Grid item xs={12} sm={6} lg={3} key={project.id}>
              <Suspense fallback={<LoadingSkeleton />}>
                <ProjectCard project={project} />
              </Suspense>
            </Grid>
          ))}
        </Grid>
      </section>

      {/* Latest Products Section */}
      <section className="space-y-6 px-4 md:px-8">
        <Typography variant="h3" className="text-2xl md:text-3xl font-bold mb-6">
          Latest Products
        </Typography>
        <Grid container spacing={4}>
          {recentProducts.map((product) => (
            <Grid item xs={12} sm={6} lg={3} key={product.id}>
              <Suspense fallback={<LoadingSkeleton />}>
                <ProductCard product={product} />
              </Suspense>
            </Grid>
          ))}
        </Grid>
      </section>
    </Box>
  );
};

export default Home;