import { Typography } from "@mui/material";

const CommentPolicy = () => {
  return (
    <div>
      <Typography variant="h1">Comment Policy</Typography>
      <Typography variant="body1" paragraph>
        We welcome and encourage comments on our blog. However, to maintain a constructive and respectful environment, we ask that all participants adhere to the following guidelines:
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Be Respectful
      </Typography>
      <Typography variant="body1" paragraph>
        Personal attacks, harassment, or abusive language directed at any individual or group will not be tolerated. Criticism should be focused on ideas, not individuals.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Stay On Topic
      </Typography>
      <Typography variant="body1" paragraph>
        Keep comments relevant to the topic being discussed. Off-topic comments may be removed to keep the conversation focused.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. No Spam or Self-Promotion
      </Typography>
      <Typography variant="body1" paragraph>
        Comments that are clearly promotional or contain links to unrelated sites will be deleted. If you have something valuable to share, contribute thoughtfully to the discussion.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. No Hate Speech or Discrimination
      </Typography>
      <Typography variant="body1" paragraph>
        Comments containing discriminatory language or hate speech based on race, gender, ethnicity, religion, disability, or sexual orientation will be removed immediately.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Moderation
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to remove any comments that violate these guidelines. Repeated violations may result in banning the user from further commenting.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Liability
      </Typography>
      <Typography variant="body1" paragraph>
        All comments are the responsibility of the commenter. By submitting a comment, you agree that the comment content is your own, and you will hold this site harmless from any and all repercussions or liability.
      </Typography>

      <Typography variant="body1" paragraph>
        Thank you for contributing to a positive and respectful discussion!
      </Typography>
    </div>
  );
};

export default CommentPolicy;
