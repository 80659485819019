import { db } from "@/overrides/firebase.override";
import { ref, get, runTransaction } from "firebase/database";
import * as Sentry from "@/overrides/sentry.override";



export const getViewCount = async (slug: string) => {
  const viewsRef = ref(db, 'views');

  try{
    const snapshot = await get(viewsRef);
    const viewsData = snapshot.val();
    const totalViews = viewsData ? viewsData.views : 0;
    return {
      total: totalViews,
      status: 200,
      message: 'success'
    }
  }catch(error){
    Sentry.captureException(error);
    return {
      total: 0,
      status: 500,
      message: 'Internal Server Error'
    }
  }
}

export const incrementViewCount = async (slug: string) => {
    const viewsRef = ref(db, 'views');
  try{
    const transactionResult = await runTransaction(viewsRef, async (transaction) => {
      const doc = await transaction.get(viewsRef);
      let views = 0;
      
      if (doc.exists) {
        const data = doc.data();
        views = data ? data.views : 0;
      }
      
      transaction.set(viewsRef, { views: views + 1 });
      
      return views + 1;
    });
    
    return {
      total: transactionResult,
      status: 200,
      message: 'success'
    }
  } catch (error) {
    Sentry.captureException(error);
    return {
      total: 0,
      status: 500,
      message: 'Internal Server Error'
    }
  }
}
