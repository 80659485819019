import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Typography, Box, Chip, Button, Grid, CardMedia } from '@mui/material';
import { Link } from 'lucide-react';
import SEO from '../components/SEO';

const fetchProjectDetails = async (id) => {
  const response = await fetch('/data/projects.json');
  const projects = await response.json();
  return projects.find(project => project.id === id);
};

const ProjectDetails = () => {
  const { id } = useParams();
  const { data: project, isLoading, error } = useQuery({
    queryKey: ['project', id],
    queryFn: () => fetchProjectDetails(id),
  });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;
  if (!project) return <Typography>Project not found</Typography>;

  const seoData = {
    title: `${project.title} - Project Details`,
    description: project.description,
    type: "article",
    url: `https://xyruscode.com.ng/projects/${project.id}`,
    image: project.imgSrc[0],
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Project",
      "name": project.title,
      "description": project.description,
      "url": `https://xyruscode.com.ng/projects/${project.id}`,
    },
  };

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>{project.title}</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {project.type === 'design' ? (
            <iframe
              style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
              width="100%"
              height="450"
              src={project.imgSrc[0]}
              allowFullScreen
            />
          ) : (
            <CardMedia
              component="img"
              image={project.imgSrc[0]}
              alt={project.title}
              sx={{ width: '100%', height: 'auto', borderRadius: 2 }}
              className="mx-auto object-cover"
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>Description</Typography>
          <Typography variant="body1" paragraph>{project.description}</Typography>
          <Typography variant="h5" gutterBottom>Tech Stack</Typography>
          <Box mb={2}>
            {project.techStack.map((tech, index) => (
              <Chip key={index} label={tech} sx={{ mr: 1, mb: 1 }} />
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={project.status === 'inactive'}abled={project.status === 'inactive'}
            startIcon={<Link />}
            href={project.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {project.type === 'design' ? 'View Design' : 'Visit Project'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectDetails;