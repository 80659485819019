import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import SEO from '../components/SEO';

const fetchProduct = async (id) => {
  const response = await fetch('/data/products.json');
  const products = await response.json();
  return products.find(product => product.id === id);
};

const ProductDetails = () => {
  const { id } = useParams();
  const { data: product, isLoading, error } = useQuery({
    queryKey: ['product', id],
    queryFn: () => fetchProduct(id),
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!lightboxOpen) return;
      switch (e.key) {
        case 'Escape':
          setLightboxOpen(false);
          break;
        case 'ArrowLeft':
          navigateImage(-1);
          break;
        case 'ArrowRight':
          navigateImage(1);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [lightboxOpen, selectedImage]);

  const navigateImage = (direction) => {
    if (!product) return;
    const currentIndex = product.images.indexOf(selectedImage);
    const newIndex = (currentIndex + direction + product.images.length) % product.images.length;
    setSelectedImage(product.images[newIndex]);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!product) return <div>Product not found</div>;

  const handleBuyNow = () => {
    const message = encodeURIComponent(`Hello, I want to buy ${product.name}.`);
    window.open(`https://wa.me/2349052727811?text=${message}`, '_blank');
  };

  const handleCallToOrder = () => {
    window.location.href = 'tel:+2349052727811';
  };

  const seoData = {
    title: product.name,
    description: product.description.slice(0, 160),
    type: "product",
    url: `https://xyruscode.com.ng/store/${product.id}`,
    image: product.images[0],
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": product.name,
      "description": product.description,
      "image": product.images[0],
      "offers": {
        "@type": "Offer",
        "price": product.price,
        "priceCurrency": "NGN",
        "availability": "https://schema.org/InStock",
        "url": `https://xyruscode.com.ng/store/${product.id}`
      }
    },
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <SEO {...seoData} />
      <h1 className="text-3xl font-bold mb-6">{product.name}</h1>
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
        <p className="font-bold">Notice:</p>
        <p>The price listed is negotiable. Contact us for the best deal!</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <Carousel className="w-full max-w-xs mx-auto">
            <CarouselContent>
              {product.images.map((image, index) => (
                <CarouselItem key={index}>
                  <Dialog open={lightboxOpen} onOpenChange={setLightboxOpen}>
                    <DialogTrigger asChild>
                      <img
                        src={image}
                        alt={`${product.name} - ${index + 1}`}
                        className="w-full h-64 object-cover rounded-lg cursor-pointer"
                        onClick={() => {
                          setSelectedImage(image);
                          setLightboxOpen(true);
                        }}
                      />
                    </DialogTrigger>
                    <DialogContent className="max-w-3xl p-0">
                      <div className="relative">
                        <img src={selectedImage} alt={product.name} className="w-full h-auto" />
                        <button
                          onClick={() => setLightboxOpen(false)}
                          className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full p-1"
                        >
                          <X size={24} />
                        </button>
                        <button
                          onClick={() => navigateImage(-1)}
                          className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-1"
                        >
                          <ChevronLeft size={24} />
                        </button>
                        <button
                          onClick={() => navigateImage(1)}
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-1"
                        >
                          <ChevronRight size={24} />
                        </button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
        <div>
          <p className="text-2xl font-semibold mb-4">₦{product.price.toLocaleString()}</p>
          <p className="text-lg mb-4">{product.description}</p>
          <p className="text-md mb-4">Type: {product.type}</p>
          <div className="space-y-4">
            <Button onClick={handleBuyNow} className="w-full">Buy Now on WhatsApp</Button>
            <Button onClick={handleCallToOrder} variant="outline" className="w-full">Call to Order</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;