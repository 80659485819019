import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import SEO from '../components/SEO';
import DisqusDiscuss from '../components/DisqusDiscuss';

const Guestbook = () => {
  const seoData = {
    title: 'Guestbook',
    description: 'Leave your message in the Xyrus Code guestbook!',
    type: 'article',
    url: 'https://xyruscode.com.ng/guestbook',
    image: 'https://xyruscode.com.ng/images/guestbook-og.jpg',
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: "Guestbook - Xyrus Code",
      description: "Leave your message in the Xyrus Code guestbook",
      url: "https://xyruscode.com.ng/guestbook",
    },
  };

  return (
    <Container maxWidth="md">
      <SEO {...seoData} />
      <Box textAlign="center" my={4}>
        <Typography variant="h2" gutterBottom>
          Guestbook
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the guestbook! Feel free to leave your thoughts, comments, or just say hello.
        </Typography>
      </Box>
      <DisqusDiscuss page={
        {
          url: 'https://xyruscode.com.ng/guestbook',
          identifier: 'guestbook',
          title: 'Guestbook',
        }
      } />
    </Container>
  );
};

export default Guestbook;
