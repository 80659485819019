import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemText, IconButton, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { X as CloseIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchBlogPosts } from '../queries';

const fetchProjects = async () => {
  const response = await fetch('/data/projects.json');
  return response.json();
};

const SearchModal = ({ open, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  const { data: blogPosts, isLoading: isBlogLoading } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: fetchBlogPosts,
  });

  const { data: projects, isLoading: isProjectsLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: fetchProjects,
  });

  const isLoading = isBlogLoading || isProjectsLoading;

  const searchResults = React.useMemo(() => {
    if (!blogPosts || !projects) return [];

    const allPosts = blogPosts.data?.publication?.posts?.edges || [];
    const allProjects = projects || [];

    const filteredResults = [
      ...allPosts.map(post => ({
        id: post.node.slug,
        title: post.node.title,
        type: 'blog',
        content: post.node.brief,
      })),
      ...allProjects.map(project => ({
        id: project.id,
        title: project.title,
        type: 'project',
        content: project.description,
      })),
    ].filter(result => 
      (result.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
       result.content.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (filter === 'all' || result.type === filter)
    );

    return filteredResults;
  }, [blogPosts, projects, searchQuery, filter]);

  const handleResultClick = (result) => {
    onClose();
    navigate(`/${result.type}/${result.id}`);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Search
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="search"
          label="Search..."
          type="text"
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={filter}
            label="Filter"
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="project">Projects</MenuItem>
            <MenuItem value="blog">Blog Posts</MenuItem>
          </Select>
        </FormControl>
        {isLoading ? (
          <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
        ) : (
          <List>
            {searchResults.map((result) => (
              <ListItem button key={result.id} onClick={() => handleResultClick(result)}>
                <ListItemText primary={result.title} secondary={result.type} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SearchModal;