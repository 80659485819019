import React, { useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Typography, Skeleton } from "@mui/material";
import { getViewCount, incrementViewCount } from "../queries/firebase.query";
import * as Sentry from "@/overrides/sentry.override";

type Props = {
  slug: string;
  isCard?: boolean;
};

const ViewCounter: React.FC<Props> = ({ slug, isCard = false }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['view-counter', slug],
    queryFn: () => getViewCount(slug),
    retry: 1, // Only retry once to quickly determine if Firebase is unavailable
  });

  const mutation = useMutation({
    mutationFn: incrementViewCount,
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    if (!isCard && !isLoading && !error && data?.status === 200) {
      const hasIncremented = sessionStorage.getItem(`view-${slug}`);
      if (!hasIncremented) {
        mutation.mutate(slug);
        sessionStorage.setItem(`view-${slug}`, 'true');
      }
    }
  }, [slug, isCard, isLoading, error, data, mutation]);

  if (isLoading) return <Skeleton width={60} />;
  if (error || data?.status !== 200) return null; // Disable counter if there's an error or non-200 status

  return <Typography variant="body2">{data.total} views</Typography>;
};

export default ViewCounter;