import { useState } from 'react';
import { Typography, Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Container } from '@mui/material';
import { render } from '@react-email/render';
import { Resend } from 'resend';
import ReCAPTCHA from "react-google-recaptcha";
import SEO from '../components/SEO';
import * as Sentry from "@/overrides/sentry.override";

const ContactEmail = ({ name, email, message }) => (
  <div>
    <h1>New Contact Form Submission</h1>
    <p>Name: {name}</p>
    <p>Email: {email}</p>
    <p>Message: {message}</p>
  </div>
);

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const seoData = {
    title: "Contact Me",
    description: "Get in touch with me for collaborations or inquiries.",
    type: "website",
    url: "https://xyruscode.com.ng/contact",
    image: "https://xyruscode.com.ng/images/contact-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "name": "Contact Me",
      "description": "Contact form for reaching out to Xyrus Code",
      "url": "https://xyruscode.com.ng/contact",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setModalContent({
        title: 'Error',
        message: 'Please complete the reCAPTCHA verification.'
      });
      setOpenModal(true);
      return;
    }

    const resend = new Resend('re_TSS8PAPu_BuyKvcZFydfBscrPmZaU1wi3');

    try {
      await resend.emails.send({
        from: 'noreply@xyruscode.com.ng',
        to: 'prince@xyruscode.com.ng',
        subject: 'New Contact Form Submission',
        html: render(ContactEmail({ name, email, message })),
      });

      setModalContent({
        title: 'Success',
        message: 'Message sent successfully!'
      });
      setOpenModal(true);
      setName('');
      setEmail('');
      setMessage('');
      setRecaptchaValue(null);
    } catch (error) {
      Sentry.captureException(error);
      setModalContent({
        title: 'Error',
        message: 'Failed to send message. Please try again.'
      });
      setOpenModal(true);
    }
  };

  return (
    <Container maxWidth="md">
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>Contact Me</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          margin="normal"
          required
        />
        <Box my={2}>
          <ReCAPTCHA
            sitekey="6LeDfysnAAAAAOdSNm-4Ltvr7NnCVHzZilX3pSIq"
            onChange={(value) => setRecaptchaValue(value)}
          />
        </Box>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Send Message
        </Button>
      </form>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>{modalContent.title}</DialogTitle>
        <DialogContent>{modalContent.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Contact;