import React, { useState } from "react";
import { Typography, Box, Button, Container } from "@mui/material";
import * as Sentry from "@/overrides/sentry.override";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const getDerivedStateFromError = () => {
    setHasError(true);
  };

  const componentDidCatch = (error, errorInfo) => {
    if (Sentry && typeof Sentry.captureException === "function") {
      Sentry.captureException(error, {
        extra: {
          errorInfo: errorInfo,
        },
      });
    } else {
      console.error("Sentry not properly initialized:", error, errorInfo);
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  if (hasError) {
    return (
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          textAlign="center"
        >
          <Typography variant="h4" color="error" gutterBottom>
            Something went wrong.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We apologize for the inconvenience. Please try reloading the page or
            contact support.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleReload}>
            Reload Page
          </Button>
        </Box>
      </Container>
    );
  }

  return children;
};

export default ErrorBoundary;
