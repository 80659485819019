import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

type DisqusDiscussProps = {
  page: {
    url: string;
    identifier: string;
    title: string;
  };
};

const DisqusDiscuss = ({ page }: DisqusDiscussProps) => {
  useEffect(() => {
    // Load Disqus script
    const d = document;
    const script = d.createElement('script');
    script.src = 'https://xyrus-code.disqus.com/embed.js'; // Replace with your Disqus shortname
    script.setAttribute('data-timestamp', String(+new Date()));
    (d.head || d.body).appendChild(script);
  }, []);

  // Configure Disqus
  useEffect(() => {
    // @ts-ignore 
    window.disqus_config = function () {
      // @ts-ignore 
      this.page.url = page.url;
      // @ts-ignore 
      this.page.identifier = page.identifier;
    };
  }, [page.url, page.identifier]);

  return (
    <Box mt={4} className="align-center w-full">
      <div id="disqus_thread" className="w-full"></div>
      <noscript>
        <Typography>
          Please enable JavaScript to view the{' '}
          <a href="https://disqus.com/?ref_noscript" target="_blank" rel="noopener noreferrer">
            comments powered by Disqus.
          </a>
        </Typography>
      </noscript>
    </Box>
  );
};

export default DisqusDiscuss;
