export const fetchBlogPosts = async () => {
    const response = await fetch('https://gql.hashnode.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query Publication {
            publication(host: "blog.xyruscode.com.ng") {
              isTeam
              title
              posts(first: 10) {
                edges {
                  node {
                    title
                    brief
                    url
                    slug
                  }
                }
              }
            }
          }
        `,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    return response.json();
};


export const fetchBlogPost = async (slug: string) => {
    const response = await fetch('https://gql.hashnode.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query Publication {
            publication(host: "blog.xyruscode.com.ng") {
              post(slug: "${slug}") {
                title
                content {
                  markdown
                }
              }
            }
          }
        `,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    return response.json();
};
