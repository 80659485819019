import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@/overrides/sentry.override";
import { Typography, Box, Container, Skeleton } from "@mui/material";
import { fetchBlogPost } from "../queries";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const BlogPost = () => {
  const { slug } = useParams();

  const { data, isLoading, error } = useQuery({
    queryKey: ["blogPost", slug],
    queryFn: () => fetchBlogPost(slug || ""),
  });

  if (isLoading) {
    return (
      <Container maxWidth="md" className="mt-8 px-4">
        <Skeleton variant="text" height={60} width="80%" />
        <Skeleton variant="text" height={20} width="40%" />
        <Skeleton variant="rectangular" height={400} />
      </Container>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return <Typography color="error">Error loading blog post</Typography>;
  }

  const post = data?.data?.publication?.post;

  if (!post) {
    return <Typography>Blog post not found</Typography>;
  }

  const seoData = {
    title: post.title,
    description: post.brief,
    type: "article",
    url: `https://xyruscode.com.ng/blog/${slug}`,
    image: post.coverImage?.url || "https://xyruscode.com.ng/og-image.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      headline: post.title,
      image: post.coverImage?.url || "https://xyruscode.com.ng/og-image.jpg",
      datePublished: post.dateAdded,
      dateModified: post.dateUpdated,
      author: {
        "@type": "Person",
        name: "Xyrus Code",
      },
    },
  };

  const imageRegex = /!\[.*?\]\((https?:\/\/.*?)\s+align="(left|center|right)"\)/g;

  const renderImage = (props) => {
    const match = imageRegex.exec(props.alt);
    if (match) {
      const [, url, alignment] = match;
      return (
        <img
          src={url}
          alt={props.alt.replace(imageRegex, '')}
          className={`max-w-full h-auto my-4 rounded-lg shadow-md ${
            alignment === 'center' ? 'mx-auto' :
            alignment === 'right' ? 'ml-auto' : ''
          }`}
          style={{ display: 'block' }}
        />
      );
    }
    return <img {...props} className="max-w-full h-auto my-4 rounded-lg shadow-md" />;
  };

  return (
    <Container maxWidth="md" className="mt-8 px-4">
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom className="text-3xl font-bold mb-4">
        {post.title}
      </Typography>
      <Box className="mb-4">
        <ViewCounter slug={slug || ""} />
      </Box>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          img: renderImage,
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                style={atomDark}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          }
        }}
      >
        {post.content.markdown}
      </ReactMarkdown>
    </Container>
  );
};

export default BlogPost;