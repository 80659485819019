import React, { useState } from 'react';
import { useMediaQuery, Box, AppBar, Toolbar, IconButton, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Menu as MenuIcon, Home, Person, Work, Book, Mail, Search as SearchIcon, ShoppingCart } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MatrixBackground from './MatrixBackground';
import SearchModal from './SearchModal';

const Layout = ({ children }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:900px)');

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const navigationItems = [
    { label: 'Home', icon: <Home />, path: '/' },
    { label: 'About', icon: <Person />, path: '/about' },
    { label: 'Projects', icon: <Work />, path: '/projects' },
    { label: 'Blog', icon: <Book />, path: '/blog' },
    { label: 'Contact', icon: <Mail />, path: '/contact' },
    { label: 'Store', icon: <ShoppingCart />, path: '/store' },
  ];

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <MatrixBackground />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h1" noWrap component="div" sx={{ flexGrow: 1, fontSize: '1.25rem' }}>
            Xyrus Code
          </Typography>
          <IconButton 
            color="inherit" 
            onClick={handleSearchOpen}
            aria-label="Open search"
          >
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flex: 1 }}>
        {isDesktop && (
          <Box
            component="nav"
            sx={{ width: 240, flexShrink: 0 }}
          >
            <Sidebar onSearchOpen={handleSearchOpen} />
          </Box>
        )}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: 3, 
            width: { sm: `calc(100% - 240px)` },
            paddingBottom: !isDesktop ? '56px' : '24px',
          }}
        >
          {children}
        </Box>
      </Box>
      {!isDesktop && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            overflowX: 'auto',
            bgcolor: 'background.paper',
          }}
        >
          <BottomNavigation
            showLabels
            value={location.pathname}
            onChange={(event, newValue) => {
              navigate(newValue);
            }}
            sx={{
              display: 'flex',
              minWidth: 'max-content',
            }}
          >
            {navigationItems.map((item) => (
              <BottomNavigationAction
                key={item.path}
                label={item.label}
                icon={item.icon}
                value={item.path}
                aria-label={`Navigate to ${item.label}`}
              />
            ))}
          </BottomNavigation>
        </Box>
      )}
      <SearchModal open={searchOpen} onClose={handleSearchClose} />
    </Box>
  );
};

export default Layout;