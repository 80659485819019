import React from 'react';
import { Card } from "@/components/ui/card";
import { Mail, Phone, MapPin } from "lucide-react";

const ResumePreview = ({ formData, resumeRef, isDarkMode }) => {
  const themeClasses = isDarkMode 
    ? "bg-gray-900 text-white" 
    : "bg-white text-gray-900";

  return (
    <Card className={`p-6 ${themeClasses}`}>
      <div ref={resumeRef} className="space-y-6">
        {formData.photo && (
          <img
            src={formData.photo}
            alt="Profile"
            className="w-32 h-32 rounded-full mx-auto object-cover"
          />
        )}
        <h2 className="text-2xl font-bold text-center">{formData.name}</h2>
        <h3 className="text-xl text-center text-gray-600">{formData.jobTitle}</h3>
        
        {(formData.email || formData.phone || formData.location) && (
          <div className="flex justify-center space-x-4">
            {formData.email && (
              <div className="flex items-center">
                <Mail className="h-4 w-4 mr-2" />
                <span>{formData.email}</span>
              </div>
            )}
            {formData.phone && (
              <div className="flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                <span>{formData.phone}</span>
              </div>
            )}
            {formData.location && (
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-2" />
                <span>{formData.location}</span>
              </div>
            )}
          </div>
        )}
        
        {formData.bio && (
          <div>
            <h4 className="text-lg font-semibold mb-2">About Me</h4>
            <p className="text-gray-600">{formData.bio}</p>
          </div>
        )}

        {formData.qualifications.some(q => q) && (
          <div>
            <h4 className="text-lg font-semibold mb-2">Qualifications</h4>
            <ul className="list-disc pl-5">
              {formData.qualifications.map((qual, index) => (
                qual && <li key={index}>{qual}</li>
              ))}
            </ul>
          </div>
        )}

        {formData.skills.some(s => s) && (
          <div>
            <h4 className="text-lg font-semibold mb-2">Skills</h4>
            <ul className="list-disc pl-5">
              {formData.skills.map((skill, index) => (
                skill && <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
        )}

        {formData.references.some(r => r) && (
          <div>
            <h4 className="text-lg font-semibold mb-2">References</h4>
            <ul className="list-disc pl-5">
              {formData.references.map((ref, index) => (
                ref && <li key={index}>{ref}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ResumePreview;