import React, { useState, useRef } from 'react';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { useToast } from "@/components/ui/use-toast";
import ResumeForm from '../components/resume/ResumeForm';
import ResumePreview from '../components/resume/ResumePreview';

const ResumeBuilder = () => {
  const { toast } = useToast();
  const resumeRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    photo: '',
    jobTitle: '',
    email: '',
    phone: '',
    location: '',
    bio: '',
    qualifications: [''],
    skills: [''],
    references: ['']
  });

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({ ...prev, photo: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleArrayFieldChange = (field, index, value) => {
    setFormData(prev => {
      const newArray = [...prev[field]];
      newArray[index] = value;
      return { ...prev, [field]: newArray };
    });
  };

  const addArrayField = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayField = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const generatePDF = async () => {
    try {
      const dataUrl = await toPng(resumeRef.current, { quality: 0.95 });
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      
      pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('resume.pdf');

      toast({
        title: "Success!",
        description: "Your resume has been downloaded.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to generate resume. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto p-4 space-y-8">
      <h1 className="text-3xl font-bold mb-6">Resume Builder</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <ResumeForm
          formData={formData}
          handlePhotoChange={handlePhotoChange}
          handleArrayFieldChange={handleArrayFieldChange}
          removeArrayField={removeArrayField}
          addArrayField={addArrayField}
          handleInputChange={handleInputChange}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          generatePDF={generatePDF}
        />
        <ResumePreview
          formData={formData}
          resumeRef={resumeRef}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default ResumeBuilder;