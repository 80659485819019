import React from 'react';
import { Typography, Box, Grid, Card, CardContent, Button, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { FileText, GithubIcon, LinkedinIcon, XIcon } from "lucide-react";
import SEO from '../components/SEO';

const About = () => {
  const seoData = {
    title: "About Me",
    description: "Learn more about my background, skills, and interests as a software developer.",
    type: "profile",
    url: "https://xyruscode.com.ng/about",
    image: "https://xyruscode.com.ng/images/about-me.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Person",
      name: "Xyrus Code",
      url: "https://xyruscode.com.ng",
      jobTitle: "Software Developer",
      description: "Passionate web developer with expertise in React and Node.js",
    },
  };

  return (
    <Box>
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom>
        About Me
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<FileText />}
        component="a"
        href="https://resume.showwcase.com/xyruscode.pdf"
        download
        sx={{ mb: 4 }}
      >
        Download Resume
      </Button>

      <Typography variant="h4" gutterBottom>
        Bio
      </Typography>
      <Typography variant="body1" paragraph>
        I&apos;m a Freelance Web Developer based in Nigeria. I spent four years in
        the university to get a B.S. in Computer Science and then went to
        Youtube to learn how to code. I&apos;m a self-taught developer. I&apos;ve been
        coding for about 8 years now. I&apos;m currently learning more about DevOps
        and Cloud Computing while also getting into UI/UX design. I&apos;m also a big
        fan of open source software and I&apos;m currently working on a few projects
        of my own.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Experience
      </Typography>
      <Typography variant="body1" paragraph>
        Let&apos;s see, I worked as an IT manager for about 16 months, fixing PCs
        and printers around the office. After that, I worked as a Junior Software
        Engineer at Epoh Music. I&apos;m currently a freelancer at the moment. I&apos;m
        spending most of my time now learning new stuff. I just might build all of
        those ideas sitting in my head while I&apos;m sitting around.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Tech Stack
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {[
          "React",
          "Node.js",
          "Python",
          "MongoDB",
          "Next.js",
          "Tailwind CSS",
          "TypeScript",
          "JavaScript",
          "HTML",
          "CSS",
          "Git",
          "GitHub",
          "GitLab",
          "Bitbucket",
          "Docker",
          "AWS",
          "Azure",
          "Google Cloud",
          "Firebase",
          "Vercel",
          "Netlify",
        ].map((tech) => (
          <Grid item key={tech}>
            <Card>
              <CardContent>
                <Typography variant="h6">{tech}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h4" gutterBottom>
        Links
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon
          onClick={() => window.open("https://github.com/xyruscode", "_blank")}
          >
            <GithubIcon />
          </ListItemIcon>
          <ListItemText
            primary="GitHub"
          />
        </ListItem>
        <ListItem
        onClick={() => window.open("https://linkedin.com/in/xyruscode", "_blank")}
        >
          <ListItemIcon>
            <LinkedinIcon />
          </ListItemIcon>
          <ListItemText
            primary="LinkedIn"
          />
        </ListItem>
        <ListItem
        onClick={() => window.open("https://twitter.com/xyruscode", "_blank")}
        >
          <ListItemIcon>
            <XIcon />
          </ListItemIcon>
          <ListItemText
            primary="Twitter"
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default About;
