import React from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Button,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import SEO from "../components/SEO";

const fetchProjects = async () => {
  const response = await fetch("/data/projects.json");
  return response.json();
};

const Projects = () => {
  const { data: projects = [], isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: fetchProjects,
  });

  if (isLoading) return <Typography>Loading...</Typography>;

  return (
    <Box className="container mx-auto px-4 py-8">
      <SEO 
        title="My Projects"
        description="Explore my latest web development projects, designs, and applications."
      />
      <Typography variant="h2" className="text-3xl font-bold mb-6">
        Projects
      </Typography>
      <Alert severity="info" sx={{ mb: 4 }}>
        Some of these projects have been taken offline due to certain circumstances. But you can still view the designs.
      </Alert>
      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Card className="h-full flex flex-col">
              {project.type === "design" ? (
                <iframe
                  src={project.imgSrc[0]}
                  className="border border-gray-200 h-48 w-full"
                  title={project.title}
                />
              ) : (
                <CardMedia
                  component="img"
                  height="200"
                  image={project.imgSrc[0]}
                  alt={project.title}
                  className="h-48 object-cover"
                />
              )}
              <CardContent className="flex-grow">
                <Typography variant="h6" component="div" className="font-bold mb-2">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" className="mb-4">
                  {project.description}
                </Typography>
                <Box className="mb-4 flex flex-wrap gap-1">
                  {project.techStack.map((tech, index) => (
                    <Chip key={index} label={tech} size="small" />
                  ))}
                </Box>
                <Box className="mt-auto flex gap-2">
                  <Button
                    component={Link}
                    to={`/projects/${project.id}`}
                    variant="contained"
                    size="small"
                  >
                    View Details
                  </Button>
                  {project.status === "active" && (
                    <Button
                      href={project.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="outlined"
                      size="small"
                    >
                      {project.type === "design" ? "View Design" : "Visit Site"}
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Projects;